body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Open Sans', sans-serif !important;
}

.fa {
  font: normal normal normal 12px/1 FontAwesome !important;
}

.faq .container .row .nav-container .nav .nav-item .material-icons {
  font-size: 24px !important;
}

.footerSection .socialIcons a .fa {
  font-size: 1.3rem !important;
}

.fa-classic,
.fa-regular,
.fa-solid,
.far,
.fas {
  font-family: "Font Awesome 6 Free" !important;
}