.h_100vh {
  height: 100vh;
}

.page_404 h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 600;
}

// header
.mainHeader {
  .logo {
    height: 38px;
  }

  .logo1 {
    height: 25px;
    margin-top: -6px;
  }
}

.mainHeader {
  p {
    font-size: 15px;
    color: #555555;
  }
}

.menu {
  color: #555555;

  &:hover {
    text-decoration: none;
  }
}

.menu.activeMenu {
  color: #001b94;
  font-weight: 600;
}

.banner {
  background-image: url('../images/HeroBanner.png');
  // min-height: calc(100vh - 74px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .bannerText {
    margin-top: 11rem;

    h1 {
      font-size: 45px;
      font-weight: bold;
      color: #ffffff;
      line-height: 3.5rem;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    }
  }
}

.banner::before {
  background-color: rgba(0, 0, 0, 0.25);
  content: '';
  display: block;
  min-height: 100%;
  position: absolute;
  width: 100%;
}

.formSection {
  width: 100%;
  background-color: #ffffff;
  height: 100%;
  border-radius: 3px;

  .tabs {
    line-height: 2rem;
    font-size: 15px;
    color: #999999;
    font-weight: bold;
    cursor: pointer;

    &.active {
      border-bottom: 1.5px solid #666666;
      color: #505050;
    }
  }

  hr {
    margin-top: -1px !important;
  }
}

//// header

// common
.title {
  font-size: 32px;
  font-weight: bold;
  color: #212529;
}

//// common

// mandatorySection
.mandatorySection {
  .cusCard {
    background-color: #f4f4f8;

    img {
      height: 3rem;
    }

    h6 {
      font-size: 13px;
      color: #222222;
    }

    p {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      color: #222222;
    }
  }
}

//// mandatorySection

// benefitsSection
.benefitsSection {
  .cusCard {
    border: 1px solid #dedede;

    img {
      height: 2.5rem;
    }

    p {
      font-size: 15px;
      font-weight: bold;
      color: #222222;
    }
  }
}

//// benefitsSection

// processSection
.cusTabs {
  p {
    font-size: 18px;
    color: #999999;
    border-bottom: 2px solid #999999;
    font-weight: 600;
    width: 12rem;
    text-align: center;
    cursor: pointer;

    &.active {
      color: #222222;
      border-bottom: 2px solid #222222;
    }
  }
}

.processSection {
  .cusCard {
    h6 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      text-align: justify;
    }
  }
}

//// processSection

// TermsAndConditions
.terms-and-condtions {
  margin: 0px;
  padding: 0px;
  background-color: #f9f9f9f9;

  .body-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px groove #ffffff7d;
    padding: 40px;
    padding-bottom: 20px;
    text-align: center;
  }

  .claim-form {
    font-size: 14px;
    color: #00bff3;
  }

  .content {
    padding: 20px 10px;
  }

  .main-class {

    p,
    li,
    span,
    table {
      color: rgba(0, 0, 0, .6);
      font-size: 14px;
      // font-family: 'Montserrat', sans-serif;
    }

    p {
      text-align: justify;
    }

    table {
      border-collapse: collapse;
      margin-bottom: 16px;

      tr {
        td {
          width: 50%;
          border: 1px solid rgba(0, 0, 0, .26);
          padding: 5px;
        }
      }
    }
  }

  .section-heading {
    color: #fe7801 !important;
    font-size: unset !important;
  }

  .li-style::before {
    content: "➣";
    padding-right: 10px;
    font-size: large;
    // list-style-type: none
  }

  .li-style {
    list-style-type: none;
    display: flex;
  }

  .link {
    color: #00bff3 !important;
  }
}

a {
  word-break: break-all;
}

.bold {
  font-weight: 700;
}

//// TermsAndConditions

// footerSection
.footerSection {
  p {
    font-size: 14.4px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #555555;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 13.5px;
      line-height: 2.07;
      color: #666666;
      cursor: pointer;
      display: table-row;
      font-weight: 600;
    }
  }

  .flight {
    width: 21px;
  }

  .copyright {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #666666;
    text-align: center;
    opacity: 0.9;
  }

  .-Copyright-2021-Ind {
    font-size: 14px;
    font-weight: 600;
    color: #666666;
    opacity: 0.7;
  }

  .socialIcons {
    a {
      color: #777777;
      font-size: 1.3rem;
    }

    a:hover {
      color: #000000;
    }
  }

  .download {
    img {
      display: block;
      height: 2.5rem;
      cursor: pointer;
    }
  }
}

//// footerSection

// form
.formSection .numOfBooking {
  p {
    margin-bottom: 0px;
  }

  h6 {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #222222;
  }

  .btn {
    border: solid 1px #027bff;
    font-size: 12px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    padding-top: 3px;
    border-radius: 50%;
    color: #027bff;
  }
}

//// form

// FAQs
.faq {
  margin: 0px;
  padding: 0px;
  // margin-top: 100px;
  background-color: #f9f9f9f9;

  .content {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px groove #ffffff7d;
    margin: 0px 50px;
    padding: 40px;
    padding-bottom: 5px;

    .body-heading {
      font-size: 26px;
    }

    .body-tagline {
      font-size: 1rem;
    }

    .header-line {
      height: 2px;
      border-bottom: 1px groove #ffffff7d;
    }
  }

  .container {
    margin: auto;
    padding: 20px 10px;

    .row {
      .nav-container {
        .nav {
          display: block;

          .nav-item {
            background: #ffffff;
            border: 1px groove #ffffff7d;
            color: black;
            padding: 15px 5px;
            font-size: 0.9rem;
            text-align: left;
            cursor: pointer;

            .icon {
              display: inline;

              img {
                padding: 0px 10px;
                margin: auto;
              }
            }

            .name {
              margin: auto;
              display: inline;
            }

            .material-icons {
              text-align: center;
              font-size: 24px;
            }
          }

          .active {
            background-color: #00bff3;
            color: #ffffff;
          }

          .need-help {
            margin-top: 20px;
            background: #ffffff;
            border: 1px groove #ffffff7d;
            color: black;
            padding: 15px 5px;
            font-size: 0.7rem;
            text-align: center;

            .icon {
              display: flex;
              align-content: center;
              justify-content: center;
              text-align: center;
            }

            .label {
              padding: 5px;
              text-align: center;
              font-size: 1rem;

              .help {
                display: inline;
                color: #fe7801;
              }
            }

            .phoneNumber {
              font-size: 16px;
              color: #00bff3;
              font-weight: 900;
            }

            .bold {
              font-weight: 900 !important;
            }

            .time {
              font-size: 0.7rem;
            }
          }
        }
      }

      .main-content {
        padding-left: 35px;
        padding-bottom: 70px;

        .section-heading {
          color: #fe7801;
        }
      }
    }

    .link {
      color: #00bff3 !important;
    }
  }

  .tab-label {
    height: 50px;
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }

  .tab-label::after {
    content: "+";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.5s;
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .register,
  .account-related,
  .order-related,
  .payment,
  .pickup-and-delivery-related,
  .insurance-related,
  .customer-related {
    .section-heading {
      color: #fe7801;
    }

    p,
    li,
    span,
    label {
      color: rgba(0, 0, 0, .6);
      font-size: 14px;
      // font-family: 'Montserrat', sans-serif !important;
    }

    .link {
      font-size: 14px;
      // font-family: 'Montserrat', sans-serif !important;
      color: #00bff3;
    }

    .content-heading {
      color: #00bff3;
      padding-bottom: 10px;
    }

    .key-point {
      color: rgba(0, 0, 0, .6);
    }

    .t-n-c {
      cursor: pointer; //payment
    }

    p {
      text-align: justify;
    }

    table {
      border-collapse: collapse;

      tr {
        td {
          width: 50%;
          border: 1px solid rgba(0, 0, 0, .26);
          padding: 5px;
        }
      }
    }

    .claim-form {
      font-size: 14px;
      color: #00bff3;
    }
  }


  // input:checked {
  //   +.tab-label {
  //     color: #fe7801;
  //     background: #fafafa;

  //     &::after {
  //       content: "\2212";
  //     }
  //   }

  //   ~.tab-content {
  //     max-height: 100%;
  //     padding: 1em;
  //     height: 100%;
  //   }
  // }

  .register,
  .account-related,
  .order-related,
  .payment,
  .pickup-and-delivery-related,
  .insurance-related,
  .customer-related {
    margin-bottom: 9px;
  }

  .tabs {
    overflow: hidden;
  }

  .tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #555555;
    background: white;
    transition: all 0.5s;
    height: 0;
  }
}

////FAQs

.amountText {
  font-size: 1.5rem;
  font-weight: 700;
}

// cancellation-and-Refund-policy

.cancellation-and-Refund-policy {
  margin: 0px;
  padding: 0px;
  background-color: #f9f9f9f9;

  .faq-body {
    .body-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px groove #ffffff7d;
      padding: 40px;
      padding-bottom: 20px;
      text-align: center;

      .body-heading {
        font-size: 26px;
      }

      .body-tagline {
        font-size: 1rem;
      }

      .label-key-point {
        color: #00bff3;
        font-size: 1rem;
      }
    }

    .content {
      color: rgba(0, 0, 0, .6);
      font-size: 14px;
      // font-family: 'Montserrat', sans-serif;
      margin-top: 20px;

      table {
        border-collapse: collapse;

        tr {
          td {
            width: 50%;
            border: 0.2px solid rgba(0, 0, 0, .26);
            padding: 5px;
          }
        }
      }

      .key-point {
        color: #00bff3;
      }
    }
  }
}

// cancellation-and-Refund-policy

.cuspP {
  color: rgb(33, 37, 41);
  font-weight: 400;
  margin: 0px;
  font-size: 13px;
}

// bookingConfirmation
.bookingConfirmation {
  .orderNo {
    color: #001b94;
  }

  .information {
    p {
      font-size: 12px;
    }
  }

  .bodyFooter {
    i {
      font-size: 1.5rem;
    }

    p {
      font-size: 14px;
    }
  }

  .help-card {
    img {
      width: 3rem;
    }

    span {
      font-size: 13px;
    }
  }
}

// bookingConfirmation

.mobileTabsView {
  p {
    font-size: 1.4rem !important;
    font-weight: 600;
  }
}

@media only screen and(max-width:768px) {
  .footerSection .copyright {
    font-size: 12px !important;
  }
}

@media only screen and(max-width:600px) {
  .css-yk16xz-control {
    margin-top: 10px !important;
  }

  .numOfBooking {
    margin: 12px;
  }

  .mandatorySection,
  .processSection,
  .benefitsSection {
    .cusCard {
      text-align: center;
    }
  }

  .processSection .cusCard h6 {
    opacity: 0.9;
    font-size: 18px;
  }

  .processSection .cusCard p {
    font-size: 16px;
    color: #555555;
    opacity: 0.7;
    text-align: justify;
  }

  .card {
    height: 294px;
  }

  .cityName {
    font-size: 18px !important;
    width: 8rem !important;
  }

  .mainHeader {
    .logo1 {
      height: 1.5rem;
    }
  }

}

// locateUs

.locateUs {
  #nav {
    width: 738px;
    overflow-x: scroll;
  }

  .card {
    // height: 277px;
    // width: 344px;
    // margin-right: 16px;
    font-size: 11px;
    box-shadow: 0 0 4.4px 0.7px rgba(2, 3, 3, 0.18);
    border-radius: 10px;
  }

  .backImage {
    height: 275px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    opacity: 0.9;
    object-position: left;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }

  .containerPadding {
    padding-left: 7.7rem;
    padding-right: 7rem;
    padding-top: 71px;
  }


  .plusBtn {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    left: -1.4rem;
    top: 39%;
  }

  .innerDiv {
    position: absolute;
    top: 0;
  }

  .cityName {
    font-size: 18px;
    width: 11rem;
    line-height: 1.1;
  }

  .airportName {
    font-size: 11px;
    font-weight: 600;
  }

  .rightBtn {
    top: 39%;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    right: -1.4rem;
  }

  .h2 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    padding-bottom: 10px;
  }

  .mngImg {
    height: 45px;
    width: 100%;
  }

  .background {
    background: #dedddd30;
  }

  .download {
    font-size: 14.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #00bff3;
  }

  .delhiImage {
    object-position: -121px 10% !important;
  }


  a:hover {
    text-decoration: none;
  }

  .stampWidth {
    width: 9rem;
    height: 56px;
  }

  .delhiStamp {
    width: 3.5rem !important;
  }

  .delhiStamp2 {
    width: 3.5rem !important;
  }

  .delhiStamp3 {
    width: 3.5rem !important;
  }

  .mumbailT2 {
    object-position: right;
  }

  .delhiImage2 {
    object-position: -52px 10% !important;
  }

  .hyderabImage {
    object-position: -48px 10% !important;
  }

  .delhi3Image {
    object-position: -48px 10% !important;
  }

  .mumbaiStamp {
    width: 3.5rem !important;
  }



}

//// locateUs

.ellipsisStyle {
  font-size: 1.3rem;
  color: #555555;
}

.css-yk16xz-control {
  border: none !important;
  border-bottom: 1px solid #cccccc !important;
  margin-top: -2px;
  font-size: 14px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: block !important;
}

.BlrImg.bangalorekial {
  object-position: left !important;
}

.BlrImg.hyderabadrgia {
  object-position: -48px 10% !important;
}

.BlrImg.delhincrt1igi {
  object-position: -121px 10% !important;
}

.BlrImg.delhincrt2igi {
  object-position: -52px 10% !important;
}

.BlrImg.delhincrt3igi {
  object-position: -48px 10% !important;
}

.BlrImg.mumbainavimumbait1csmia {
  object-position: left !important;
}

.footerDisclaimer {
  color: #777777 !important;
  font-size: 13px !important;
  text-align: justify;
}

@media (max-width:380px) {
  .nav-item {
    padding: 5px 5px;
  }

  .name span {
    display: none;
  }

  .main-content {
    padding-right: 35px;
    line-height: 1.3;
  }

  .web-view-faq {
    display: none !important;
  }

  .mobile-view-faq {
    display: block !important;
  }
}

@media (max-width:500px) {
  .need-help {
    display: none;
  }

  .web-view-faq {
    display: none !important;
  }

  .mobile-view-faq {
    display: block !important;
  }
}

@media (max-width:992px) {
  .material-icons {
    display: none;
  }

  .icon {
    text-align: center;
  }

  .name {
    text-align: center;
  }

  .web-view-faq {
    display: none !important;
  }

  .mobile-view-faq {
    display: block !important;
  }

  .tab-label-data {
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }
}

input:checked {
  +.tab-label-data {
    color: #ffffff !important;
    background: #00bff3 !important;

    &::after {
      transform: rotate(90deg) !important;
    }
  }

  ~.tab-content-data {
    max-height: 100%;
    padding: 1em;
    height: 100%;
  }
}

.tab-label-data::after {
  display: block;
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.5s;
}

.dropdown-label {
  padding: 0px;
}

.tab {
  // margin-bottom: 9px;
  width: 100%;
  color: #555555;
  overflow: hidden;

  &-label {
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #ffffff;
    font-weight: bold;
    cursor: pointer;

    /* Icon */
    &:hover {
      background: darken(#ffffff, 10%);
    }

    &::after {
      content: "\002B";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .50s;
    }
  }

  &-label-data {
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #ffffff;
    font-weight: bold;
    cursor: pointer;

    /* Icon */
    &:hover {
      background: darken(#ffffff, 10%);
    }

    &::after {
      display: block;
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .50s;
    }
  }

  &-content {
    max-height: 0;
    padding: 0 1em;
    color: #555555;
    background: white;
    transition: all .50s;
    height: 0;
  }

  &-content-data {
    max-height: 0;
    padding: 0 1em;
    color: #555555;
    background: white;
    transition: all .50s;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #ffffff;
    cursor: pointer;

    &:hover {
      background: darken(#ffffff, 10%);
    }
  }
}

// :checked
input:checked {
  +.tab-label {
    color: #fe7801;
    background: #fafafa;

    &::after {
      content: "\2212";
      //  transform: rotate(90deg);
    }
  }

  ~.tab-content {
    max-height: 100%;
    padding: 1em;
    height: 100%;
  }
}

#need-help {
  .need-help-body {
    background: white;
    border: 1px groove #f9f9f98f;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: -1px 0px 16px -10px #88888C;

    .need-help-text {
      display: flex;
      font-size: 20px;
      margin-bottom: -5px;
      margin-top: 15px;

      .help {
        color: #fe7801;
      }
    }

    .contact-number {
      color: #00bff3;
      font-size: 16px;
    }

    .timings {
      color: #555555;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }

}

.mobile-view-faq {
  display: none;
}

.extrapadding3 {
  padding-bottom: 4em !important;
}

.paymentmobimage {
  margin-left: -6px !important;
}

.secdecpadding {
  padding-bottom: 3.4em !important;
}

.accountpadbot {
  padding-bottom: 3.4em !important;
}

.moderefundpadding {
  padding-bottom: 5em !important;
}

.moderefundpadding2 {
  padding-bottom: 7em !important;
}