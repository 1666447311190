.formSection {
  .form-control {
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
    font-size: 15px;
    color: #666666;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  label {
    font-size: 15px;
    color: #666666;
  }

  p {
    font-size: 12px;
    color: #999999;
  }

  p.nav-link {
    background-color: #d7d7d7;
    font-size: 18px;
    font-weight: 600;
    color: #555555;

    &.active {
      font-weight: bold;
      color: #222222;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: bold;
    color: #222222;
  }
}

.border.borderNone {
  border: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 600px) {
  .formSection .form-control {
    font-size: 15px !important;
    color: #666666;
    font-weight: 600;
    opacity: 0.7;
    padding: 0px 2px 4px 0px;
    margin: 12px 0;
  }

  .border.borderNone {
    border: 1px solid #dee2e6 !important;
    border-top: none !important;
  }
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

select {
  -webkit-appearance: none;
}

.customDropdown {
  cursor: default;

  .dropdown-toggle {
    white-space: break-spaces;
    height: 100%;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-item {
    display: grid;
    padding: 0px 8px;
    font-size: 12px;
  }

  .dropdown-item:hover {
    background-color: #fff !important;
  }

  .dropdown-menu {
    width: 100%;
  }

  input {
    font-size: 12px;
    border: 1px solid #ccc;
  }

  .addressHeading {
    text-align: center;
    color: #000;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .submitBtn {
    padding: 5px 10px !important;
  }
}

.timeLine {
  font-weight: 600 !important;
  font-size: 9px !important;
  margin-bottom: 0;
  color: #000 !important;
}

.timeLineTime {
  font-weight: 600 !important;
  font-size: 9px !important;
  margin-bottom: 0;
}

.txt-left::-webkit-date-and-time-value {
  text-align: left;
}

.gap {
  gap: 15px 0px;
}

.timeHeading {
  font-size: 12px;
  font-weight: 800;
}