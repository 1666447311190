@import './_customClass';
@import './_rewriteClass';
@import './styleGuide';

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button,
ul,
li,
div {
  font-family: 'Open Sans', sans-serif !important;
}


.Toastify__close-button {
  padding: 13px !important;
}

@media only screen and (min-width: 480px) {
  .Toastify__toast-container {
    width: fit-content !important;
  }
}

.infoParagraph p,
.infoParagraph span {
  color: #020203;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-weight: 400;
  letter-spacing: normal;
  orphans: 2;
  text-align: left;
  text-indent: 0px;
  text-transform: none;
  white-space: normal;
  widows: 2;
  word-spacing: 0px;
  -webkit-text-stroke-width: 0px;
  text-decoration-style: initial;
  text-decoration-color: initial;
  margin: 0px;
  font-size: 13px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: 23px;
}

.infoParagraph .heading {
  font-weight: 600 !important;
  font-size: 14px !important;
}

 .message {
  display: flex;
  padding-top: 3px;
  align-items: baseline;

  p {
    font-size: 9px;
    line-height: 12px;
    color: #000;
    padding-left: 3px;
    margin-bottom: 0;
  }
  .mIcon {
  font-size: 10px;
  padding: 0px 6px;
  border-radius: 50%;
  background-color: #1b7dc1;
  color: #fff;
  max-height: 14px;
}
}