.submitBtn {
  padding: 12px 32px;
  border-radius: 4px;
  background-color: #001b94;
  color: #ffffff;
  font-weight: 600;
  border: none;

  &:disabled {
    background: #dddddd;
  }
}

.confirm {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #001b94;
  color: #ffffff;
  font-weight: 600;
  border: none;
  font-size: 12px !important;
}

.cusBtn {
  padding: 6px 15px;
  border-radius: 4px;
  background-color: #daf1ff;
  font-size: 15px;
  font-weight: 600;
  color: #027bff;
  border: none;
}

.activeRadio {
  color: #222222 !important;
  font-weight: 600;
}

.mt6px {
  margin-top: 6px;
}

.cursorPointer {
  cursor: pointer;
}

.form-control.cusErrors {
  border-bottom: 1px solid #da0000;
}

.fs12 {
  font-size: 15px !important;
}

.fw6 {
  font-weight: 600;
}

.cusError {
  font-size: 12px;
  color: #cc0001;
}

.headerF {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  // border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}

.desc {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #565a5c;
  margin-bottom: 40px;
}

.feature-heading {
  line-height: 20px;
  text-align: center;
  padding-top: 10px;
  color: #333333;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}


#nav {
  width: 738px;
  overflow-x: scroll;
}

.card {
  // height: 277px;
  // width: 380px;
  // margin-right: 16px;
  font-size: 11px;
  box-shadow: 0 0 4.4px 0.7px rgba(2, 3, 3, 0.18);
  border-radius: 10px;
}

.backImage {
  height: 275px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0.9;
  object-position: left;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.7);
}

.containerPadding {
  padding-left: 7.7rem;
  padding-right: 7rem;
  padding-top: 71px;
}

.innerDiv {
  position: absolute;
  top: 0;
}

.cityName {
  font-size: 20px;
  width: 10rem;
  line-height: 1.1;
}

.airportName {
  font-size: 11px;
  font-weight: 600;
}

.rightBtn {
  top: 39%;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: -10px;
}

.h2 {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  padding-bottom: 10px;
}


// .background {
//   background: #dedddd30;
// }

.download {
  font-size: 14.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #00bff3;
}

.delhiImage {
  object-position: -121px 10% !important;
}


a:hover {
  text-decoration: none;
}

.stampWidth {
  width: 5rem;
  height: 56px;
}

.delhiStamp {
  width: 5rem !important;
}

.delhiStamp2 {
  width: 3.5rem !important;
}

.delhiStamp3 {
  width: 3.5rem !important;
}

.mumbailT2 {
  object-position: right;
}

.delhiImage2 {
  object-position: -52px 10% !important;
}

.hyderabImage {
  object-position: -48px 10% !important;
}

.delhi3Image {
  object-position: -48px 10% !important;
}

.mumbaiStamp {
  width: 3.5rem !important;
}

.BlrImg {
  height: 275px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* opacity: 0.9; */
  // -o-object-position: left;
  /* object-position: left; */
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: brightness(0.7);
  filter: brightness(0.7);
}

.imgSiz {
  height: 3rem;
}

.footerText {
  color: #666666;
  ;
}

@media only screen and(max-width: 600px) {
  .oneWayRound {
    margin-top: 24px;
    margin-bottom: 10px !important;
  }

  .submitBtn {
    width: 100%;
  }

  // .safetyFirstHide {
  //   display: none;
  // }

  .card {
    height: 283px;
  }

  .card-body {
    padding: 0px !important;
    line-height: normal;
  }

  .BlrImg {
    height: 283px;
  }

  .headerF {
    padding-top: 15px;
  }
}

.timeToMeet .fa-solid {
  font-size: 12px !important;
  cursor: pointer;
}